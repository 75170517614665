import {
  identity, reduce, reduced, uncurryN, curryN, curry, compose, map, trim, toUpper, join, sort,
  prepend, ifElse, length, always,
} from 'ramda';
import {
  encodeId, splitConversationId, isPrivateConversation, isGroup, getConversationName, isCompany,
  isAgent, getMessageContent, getCompanyChatbots,
  CONVERSATION_TYPE, CONVERSATION_EXPOSURE, CONTENT_TYPE, DELIVERY_STATE,
} from '@twnel/utils-js/lib/web';
import { signedUrlS3 } from '@twnel/utils-js/lib/aws';
import {
  formatPhoneNumber, dateToFormat, getTexts, urlRegex,
} from '@twnel/web-components';
import {
  getAWS, getSelectedCompanyId, getUserAgentId, getCompany, getAgent,
} from '@twnel/companies-login';
import { getContact, getCleanUserProfiles } from 'src/data/selectors';

const { AGENT, BUSINESS, GROUP } = CONVERSATION_TYPE;
const { ACTIVE, PENDING } = CONVERSATION_EXPOSURE;
const {
  AUDIO, IMAGE, VIDEO, LOCATION, PDF, SESSION,
} = CONTENT_TYPE;
const { NOT_SENT, QUEUED } = DELIVERY_STATE;

export const logger = curryN(2, ({
  debug, verbose, error, warning,
}, ...args) => {
  if (!debug && !error) {
    return;
  }
  const [content, ...details] = args;
  const contentObj = !error || content instanceof Error
    ? content : new Error(content);
  let method;
  if (error) {
    method = 'error';
  } else if (warning) {
    method = 'warn';
  } else if (verbose) {
    method = 'debug';
  }
  // eslint-disable-next-line no-console
  console[method](contentObj, ...details);
});

export const tagsToId = ifElse(
  length,
  compose(
    join(':'),
    prepend('t'),
    sort((tagA, tabB) => tagA.localeCompare(tabB)),
    map(compose(trim, toUpper)),
  ),
  always('all'),
);

export const compareConversationsBy = uncurryN(
  3,
  (attribute) => (conversationA, conversationB) => {
    if (conversationA[attribute] === conversationB[attribute]) {
      return conversationB.sortStamp - conversationA.sortStamp;
    }
    return +conversationB[attribute] - +conversationA[attribute];
  },
);

// string|conversation|company|agent|user -> { id, name, image }
export const modelAvatar = (...args) => {
  const obj = reduce(
    (acc, value) => (acc ? reduced(acc) : value),
    false,
    args,
  );

  if (isPrivateConversation(obj) || isGroup(obj)) {
    const {
      id, name, photo, image,
    } = obj;
    const regex = urlRegex();
    const registeredName = getConversationName(obj);
    return {
      id,
      name: registeredName?.replace(regex, '').trim() || name || formatPhoneNumber(id),
      image: photo || image,
      urls: registeredName?.match(regex, '') || undefined,
    };
  }

  if (isAgent(obj)) {
    const { id, name, photo } = obj;
    return {
      id,
      name: name || formatPhoneNumber(id),
      image: photo,
    };
  }

  if (isCompany(obj)) {
    const { id, name, logo } = obj;
    return {
      id,
      name: name || id,
      image: logo,
    };
  }

  if (typeof obj === 'object') {
    const { id, name, photo } = obj;
    return {
      id,
      name: name || formatPhoneNumber(id),
      image: photo,
    };
  }

  if (typeof obj === 'string') {
    return {
      id: obj,
      name: formatPhoneNumber(obj),
    };
  }

  throw new Error(`Unexpected object passed to modelAvatar: ${obj}`);
};

export const agentAvatar = (companyId, agentId, state) => {
  const company = getCompany(companyId, state);
  const result = {
    id: agentId,
    companyId,
    companyName: company?.name || companyId,
    companyLogo: company?.logo,
  };

  const botMatch = agentId.match(/^Bot - (.*)/);
  if (botMatch) {
    return {
      ...result,
      avatarType: 'bot',
      name: botMatch[1],
    };
  }

  const agent = getAgent(companyId, agentId, state);
  const selectedCompanyId = getSelectedCompanyId(state);
  result.avatarType = 'agent';
  if (agent?.name) {
    result.name = agent?.name;
  } else if (companyId === selectedCompanyId) {
    result.name = formatPhoneNumber(agentId);
  } else {
    const texts = getTexts(state);
    const hashId = encodeId(agentId).slice(-4).toUpperCase();
    result.name = `${texts('Agent')} • ${hashId}`;
  }
  result.image = agent?.photo;
  return result;
};

export const getBotAvatar = (companyId, botId, state) => {
  const company = getCompany(companyId, state);
  const chatbots = getCompanyChatbots(company);
  const result = {
    id: botId,
    avatarType: 'bot',
    companyId,
    companyName: company?.name || companyId,
    companyLogo: company?.logo,
  };
  if (chatbots[botId]) {
    result.name = chatbots[botId].message;
  } else {
    const texts = getTexts(state);
    const hashId = encodeId(botId).slice(-4).toUpperCase();
    result.name = `${texts('Bot')} • ${hashId}`;
  }
  return result;
};

export const conversationAvatar = curry((conversation, state) => {
  if (conversation?.type === GROUP) {
    const { company: companyId } = conversation;
    const selectedCompanyId = getSelectedCompanyId(state);
    if (companyId === selectedCompanyId) {
      return {
        ...modelAvatar(conversation),
        avatarType: 'group',
      };
    }
    const company = getCompany(companyId, state);
    return {
      ...modelAvatar(conversation),
      avatarType: 'group',
      companyId,
      companyName: company?.name || companyId,
      companyLogo: company?.logo,
    };
  }
  if (conversation?.type === BUSINESS) {
    const company = getCompany(conversation.id, state);
    return {
      ...modelAvatar(company, conversation),
      avatarType: 'company',
    };
  }
  if (conversation?.type === AGENT) {
    const { id, agent: agentId } = splitConversationId(conversation);
    return agentAvatar(id, agentId, state);
  }
  return modelAvatar(conversation);
});

export const conversationNotification = (conversation, state) => {
  const texts = getTexts(state);
  const { name, image, companyName } = conversationAvatar(conversation, state);

  let title;
  if (conversation.exposure === PENDING) {
    title = texts('New pending conversation');
  } else if (conversation.exposure === ACTIVE) {
    title = texts('New assigned conversation');
  } else {
    throw new Error('Unexpected conversation type');
  }

  return {
    title,
    image,
    body: companyName ? `(${companyName}) ${name}` : name,
    tag: conversation.id,
  };
};

export const messageAvatar = (message, state) => {
  const {
    author, companyId, info, isBroadcast,
  } = message;
  if (!companyId) {
    const model = getContact(getSelectedCompanyId, author, state);
    const profile = getCleanUserProfiles(state)[author];
    return modelAvatar(model, profile ? { id: author, ...profile } : author);
  }

  const company = getCompany(companyId, state);
  if (isBroadcast || author === companyId) {
    return {
      ...modelAvatar(company, companyId),
      avatarType: 'company',
    };
  }
  if (info.chatbot_id) {
    return getBotAvatar(companyId, info.chatbot_id, state);
  }
  return agentAvatar(companyId, author, state);
};

const parseSessionMessage = ({
  session, text, agent, action, topic,
}, texts, showDetails = false) => {
  switch (session) {
    case 'start':
      return {
        content: texts('Assigned to'),
        agent,
        displayDate: true,
      };
    case 'end': {
      let content;
      if (topic?.name) {
        // eslint-disable-next-line no-template-curly-in-string
        content = texts('Resolved and classified as *${0}* by')
          .replace(/\$\{0\}/g, topic?.name);
      } else {
        content = texts('Resolved by');
      }
      return {
        content,
        agent,
        displayDate: true,
      };
    }
    case 'classify':
      return {
        content: texts('Session classified as'),
        detail: text,
      };
    case 'open':
      return {
        content: texts('Agent request'),
        separator: true,
      };
    case 'close':
      return {
        content: texts('Resolved by'),
        agent,
      };
    case 'new':
      return {
        content: texts('New request'),
        separator: true,
      };
    case 'bot':
      return {
        content: texts('Bot interaction'),
        separator: true,
      };
    case 'control':
      return {
        content: texts('Transaction'),
        detail: action === 'transfer' ? texts('transferred') : texts('cancelled'),
        displayDate: showDetails,
      };
    case 'broadcast': {
      if (text === 'preview') {
        return {
          content: texts('Preview'),
          separator: true,
          warning: true,
        };
      }

      let content;
      if (Number.isNaN(Number.parseInt(text, 10))) {
        content = texts('Dispatched by');
      } else if (text === 1) {
        content = texts('Sent to a single contact by');
      } else {
        // eslint-disable-next-line no-template-curly-in-string
        content = texts('Sent to ${0} contacts by')
          .replace(/\$\{0\}/g, text);
      }
      return {
        content,
        agent,
        separator: true,
        displayDate: true,
      };
    }
    default:
      return { content: text || session };
  }
};

export const messageSummary = (message, texts = identity) => {
  const { type } = message;
  const { content, body } = getMessageContent(message);
  switch (type) {
    case AUDIO:
      return {
        content: body || texts('Audio'),
        icon: 'fas fa-microphone',
      };
    case IMAGE:
      return {
        content: body || texts('Image'),
        icon: 'fas fa-file-image',
      };
    case VIDEO:
      return {
        content: body || texts('Video'),
        icon: 'fas fa-file-image',
      };
    case LOCATION:
      return {
        content: body || texts('Location'),
        icon: 'fas fa-map-marker-alt',
      };
    case PDF:
      return {
        content: body || texts('PDF'),
        icon: 'fas fa-file-pdf',
      };
    default: {
      return { content };
    }
  }
};

export const messageDescription = (message, author, texts = identity) => {
  let description;
  const { content } = getMessageContent(message);
  switch (message.type) {
    case AUDIO: {
      const body = content.body || texts('sent an audio file');
      description = author ? `${author} ${body}` : body;
      break;
    }
    case IMAGE: {
      const body = content.body || texts('sent an image');
      description = author ? `${author} ${body}` : body;
      break;
    }
    case VIDEO: {
      const body = content.body || texts('sent a video');
      description = author ? `${author} ${body}` : body;
      break;
    }
    case LOCATION: {
      const body = content.body || texts('shared a location');
      description = author ? `${author} ${body}` : body;
      break;
    }
    case PDF: {
      const body = content.body || texts('sent a PDF file');
      description = author ? `${author} ${body}` : body;
      break;
    }
    case SESSION: {
      const { content: body } = parseSessionMessage(content, texts);
      if (content.session === 'start' || content.session === 'end') {
        description = `${body} ${author}`;
      } else if (content.session === 'note') {
        description = author
          ? `${author} ${texts('annotated')}: ${content.text}`
          : content.text;
      } else {
        description = body;
      }
      break;
    }
    default: {
      description = author ? `${author}: ${content}` : content;
    }
  }
  return description;
};

export const shouldNotifyMessage = (conversation, message, state) => {
  // Ignore bot messages
  const { info } = message;
  if (info && (info.chatbot_id || info.output || info.type === 'faq')) {
    return false;
  }

  // Ignore messages authored by the company, authored by the user agent and info messages
  const companyId = getSelectedCompanyId(state);
  const userAgentId = getUserAgentId(state);
  const { type, author, companyId: messageCompanyId } = message;
  if (
    author === companyId
    || (messageCompanyId === companyId && author === userAgentId)
    || type === SESSION
  ) {
    return false;
  }

  // Notify all group messages
  const { type: conversationType } = conversation;
  if (conversationType === GROUP || conversationType === BUSINESS) {
    return true;
  }

  // Notify active conversations that are assigned to the user
  const userId = getUserAgentId(state);
  const { exposure, agent } = conversation;
  if (exposure === ACTIVE && agent?.id === userId) {
    return true;
  }

  // Notify pending conversations from either customers, or agents contacting the user
  if (exposure === PENDING) {
    const { resource } = splitConversationId(conversation);
    return conversationType !== AGENT || resource === userId;
  }

  return false;
};

export const messageNotification = (conversation, message, state) => {
  const texts = getTexts(state);
  const { name, image, companyName } = messageAvatar(message, state);
  const owner = isGroup(conversation) ? conversation.name : companyName;
  const body = messageDescription(message, owner ? name : null, texts);
  return {
    title: owner || name,
    image,
    body,
    tag: conversation.id,
  };
};

const getMediaContent = (aws, content) => {
  if (content instanceof File || /^https?:\/\/[^\s]{2,}$/i.test(content)) {
    return content;
  }
  return signedUrlS3({ aws, key: content });
};

export const parseMessage = curry((showDetails, message, state) => {
  const {
    id, date, displayDate, type, author, hideAuthor, companyId, deliveryState, info,
  } = message;
  const {
    content, body, name, size, extension, detail,
  } = getMessageContent(message);

  let result = { id, date, displayDate };
  if (type === SESSION && content.session !== 'note') {
    const texts = getTexts(state);
    const {
      content: sessionContent, detail: sessionDetail, agent,
      displayDate: displaySessionDate, separator, warning,
    } = parseSessionMessage(content, texts, showDetails);
    result = {
      ...result,
      content: sessionContent,
      displayDate: displaySessionDate,
      separator,
      warning,
      type: 'info',
    };
    if (sessionDetail) {
      result.name = sessionDetail;
    } else if (agent && companyId) {
      const { name: agentName, avatarType } = agentAvatar(companyId, agent, state);
      if (avatarType === 'bot') {
        result.content = `${result.content} ${texts('Bot')}:`;
      }
      result.name = agentName;
    }
    return result;
  }

  result.sent = deliveryState > NOT_SENT;
  if (deliveryState === QUEUED) {
    result.queued = true;
  }
  if (!hideAuthor) {
    result.author = author;
  }

  switch (type) {
    case AUDIO:
      result.type = 'audio';
      break;
    case IMAGE:
      result.type = 'image';
      break;
    case VIDEO:
      result.type = 'video';
      break;
    case LOCATION:
      result.type = 'map';
      break;
    case PDF:
      result.type = 'pdf';
      break;
    default:
      result.type = 'text';
  }

  switch (type) {
    case IMAGE: {
      const aws = getAWS(state);
      result = {
        ...result,
        content: getMediaContent(aws, content),
      };
      if (showDetails) {
        result.meta = { name, size, extension };
      }
      if (body) {
        result.body = body;
      }
      break;
    }
    case AUDIO:
    case VIDEO:
    case PDF: {
      const aws = getAWS(state);
      result = {
        ...result,
        content: getMediaContent(aws, content),
        meta: { name, size, extension },
      };
      if (body) {
        result.body = body;
      }
      break;
    }
    case LOCATION:
      if (content.image) {
        const aws = getAWS(state);
        content.image = getMediaContent(aws, content.image);
      }
      result = {
        ...result,
        content,
        meta: { name, detail },
      };
      if (body) {
        result.body = body;
      }
      break;
    case SESSION: {
      const texts = getTexts(state);
      result = {
        ...result,
        content: content.text,
        visibilityNotice: texts('Only visible to agents'),
        highlight: true,
      };
      break;
    }
    default: {
      let parsedContent;
      const { output } = info;
      if (output?.date) {
        parsedContent = dateToFormat(output.date);
      } else if (output?.type === 'phone') {
        parsedContent = formatPhoneNumber(content);
      } else {
        parsedContent = content;
      }
      result.content = parsedContent;
    }
  }

  return {
    ...messageAvatar(message, state),
    ...result,
  };
});
