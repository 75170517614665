import {
  assoc, isEmpty, compose, not, isNil,
} from 'ramda';
import to from 'await-to-js';
import {
  splitId, splitConversationId, isGroup, parseApiConversation, cleanConversationObject,
  CONVERSATION_TYPE, CONVERSATION_EXPOSURE,
} from '@twnel/utils-js/lib/web';
import { getSelectedCompanyId, getUserAgentId } from '@twnel/companies-login';
import { CONVERSATIONS } from 'src/data/constants';
import { getConversation, getMessage } from 'src/data/selectors';
import {
  conversationNotification, shouldNotifyMessage, messageNotification,
} from 'src/data/util';
import { updateConversations } from '../basic';
import { fetchConversationsOrigin } from './contacts';
import { archiveConversation, cleanAndFilter } from './util';

const {
  AGENT, BUSINESS,
} = CONVERSATION_TYPE;
const {
  ACTIVE, PENDING, INACTIVE,
} = CONVERSATION_EXPOSURE;

export const onSocketConversations = (rawConversations = []) => async (
  dispatch,
  getState,
  getContext,
) => {
  let conversations = await dispatch(fetchConversationsOrigin(
    rawConversations.map(parseApiConversation),
    { ignoreCache: false },
  )).then(cleanAndFilter(getState()));

  if (isEmpty(conversations)) {
    return;
  }

  const userAgentId = getUserAgentId(getState());
  const companyId = getSelectedCompanyId(getState());
  conversations = conversations.map((conversation) => {
    const existing = getConversation(companyId, conversation.id, getState());
    if (!existing && conversation.exposure === INACTIVE) {
      return null;
    }

    if (
      (existing?.exposure !== PENDING && conversation.exposure === PENDING)
      || (
        (existing?.exposure !== ACTIVE && existing?.agent?.id !== userAgentId)
        && (conversation.exposure === ACTIVE && conversation.agent?.id === userAgentId)
      )
    ) {
      const { notificationsManager } = getContext();
      const notification = conversationNotification(conversation, getState());
      notificationsManager.sendNotification(notification);
    }

    const { lastMessage: message } = conversation;
    const existingMessage = getMessage(companyId, conversation.id, message?.id, getState());
    if (
      message && !existingMessage?.notified
      && shouldNotifyMessage(conversation, message, getState())
    ) {
      const { notificationsManager } = getContext();
      const notification = messageNotification(conversation, message, getState());
      notificationsManager.sendNotification(notification);
      return {
        ...conversation,
        lastMessage: {
          ...message,
          notified: true,
        },
      };
    }
    return conversation;
  }).filter(compose(not, isNil));

  if (isEmpty(conversations)) {
    return;
  }

  dispatch(updateConversations(companyId, conversations));
};

export const onTypingEvent = (rawId) => (dispatch, getState, getContext) => {
  const { id: conversationId, agent } = splitId(rawId);
  if (agent) {
    // Impossible to identify the conversation without the owner id.
    return;
  }

  const companyId = getSelectedCompanyId(getState());
  const conversation = () => getConversation(companyId, conversationId, getState());
  if (!conversation()) {
    return;
  }

  const updateTyping = (typing) => {
    const instance = conversation();
    if (!instance) return;
    dispatch(updateConversations(companyId, [
      assoc('typing', typing, instance),
    ]));
  };

  const { cacheStore } = getContext();
  const typingTimeouts = cacheStore.get(`${CONVERSATIONS}/typingTimeouts`);

  updateTyping(true);
  clearTimeout(typingTimeouts.get(conversationId));
  typingTimeouts.set(conversationId, setTimeout(() => {
    typingTimeouts.delete(conversationId);
    updateTyping(false);
  }, 3000));
};

export const freeConversation = (conversationId) => async (dispatch, getState, getContext) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    throw Error('Conversation not found in memory');
  }

  if (isGroup(conversation) || conversation.exposure === PENDING) {
    return;
  }

  const { exposure: originalExposure } = conversation;
  dispatch(updateConversations(companyId, [
    assoc('exposure', PENDING, conversation),
  ]));

  const { socket } = getContext();
  try {
    await socket.emit('free', {
      ...cleanConversationObject(conversation),
      exposure: conversation.exposure,
    });
  } catch (error) {
    const updatedConversation = getConversation(companyId, conversationId, getState());
    dispatch(updateConversations(companyId, [
      assoc('exposure', originalExposure, updatedConversation),
    ]));
  }
};

export const lockConversation = (conversationId, agentId) => async (
  dispatch,
  getState,
  getContext,
) => {
  const companyId = getSelectedCompanyId(getState());
  const conversation = getConversation(companyId, conversationId, getState());
  if (!conversation) {
    throw Error('Conversation not found in memory');
  }

  if (
    isGroup(conversation)
    || (conversation.exposure === ACTIVE && (!agentId || conversation.agent?.id === agentId))
  ) {
    return true;
  }

  const { api, socket, cacheStore } = getContext();
  const lockRequests = cacheStore.get(`${CONVERSATIONS}/lockRequests`);
  let promise = lockRequests.get(conversationId);
  if (promise) {
    return promise;
  }

  const assignto = agentId ?? getUserAgentId(getState());
  const lockAttempt = () => socket.emit('lock', {
    ...cleanConversationObject(conversation),
    exposure: conversation.exposure,
    agent: { id: assignto },
  });

  promise = (async function lock() {
    let [error] = await to(lockAttempt());
    if (/^conversation not found$/i.test(error)) {
      [error] = await to(api.conversations.update([conversation]));
      if (!error) {
        [error] = await to(lockAttempt());
      }
    }
    lockRequests.delete(conversationId);
    if (error) {
      throw error;
    }
  }());

  lockRequests.set(conversationId, promise);
  return promise;
};

export const closeConversation = (conversationId) => async (dispatch, getState, getContext) => {
  const selectedCompanyId = getSelectedCompanyId(getState());
  const conversation = getConversation(selectedCompanyId, conversationId, getState());
  if (!conversation) {
    throw Error('Conversation not found in memory');
  }

  if (isGroup(conversation) || conversation.exposure === INACTIVE) {
    return;
  }

  let remove;
  if (conversation.type === AGENT) {
    const { id: companyId } = splitConversationId(conversation);
    remove = selectedCompanyId === companyId;
  } else {
    remove = conversation.type === BUSINESS;
  }

  const archivedConversation = archiveConversation(getState(), conversation, { remove });
  dispatch(updateConversations(selectedCompanyId, [archivedConversation]));

  const conversationData = {
    ...cleanConversationObject(conversation),
    exposure: conversation.exposure,
  };

  const { topic_selected: topicSelected } = conversation.data || {};
  if (topicSelected) {
    conversationData.data = assoc(
      'topic_selected',
      topicSelected,
      conversationData.data,
    );
  }

  const { socket } = getContext();
  try {
    await socket.emit('close', conversationData);
  } catch (error) {
    updateConversations(selectedCompanyId, [conversation]);
  }
};
